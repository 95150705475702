import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import 'swiper/scss/navigation'
import { Navigation } from 'swiper'

import FoundationCard from './FoundationCard'

import './styles.scss'

const FoundationTopCarousel = () => {
  return (
    <div className="foundation-carousel-bottom my-0 mx-auto py-0 px-3">
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop
        navigation
        modules={[Navigation]}
        className="foundation mySwiper"
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
      >
        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['1_miratus1.png']}
            altSet={['projeto miratus']}
            title="MIRATUS"
            paragraph="ONG  que atua em comunidades vulneráveis no Rio de Janeiro, oferecendo educação, esportes e cultura para crianças e jovens. A organização é conhecida por seu trabalho em áreas como o Complexo do Alemão, promovendo oportunidades e transformação social através de suas iniciativas ( focando no badminton)"
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['2_Gol_De_Letra1.png']}
            altSet={['projeto GOL DE LETRA']}
            title="GOL DE LETRA"
            paragraph='Organização foi fundada pelos ex-jogadores de futebol Raí e Leonardo. A ONG trabalha com crianças e adolescentes em comunidades carentes, oferecendo programas educacionais, culturais e esportivos como forma de promover o desenvolvimento humano e social desses jovens. O nome "Gol de Letra" faz alusão tanto à expressão futebolística quanto ao desejo de marcar um gol na vida dessas crianças, ajudando-as a alcançar seus objetivos e sonhos'
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['5_Casa_do_Zezinho1.png']}
            altSet={['projeto CASA DO ZEZINHO']}
            title="CASA DO ZEZINHO"
            paragraph="A Casa do Zezinho é uma ONG localizada na cidade de São Paulo. Fundada em 1994, tem como missão promover o desenvolvimento integral de crianças, adolescentes e jovens em situação de vulnerabilidade social por meio de atividades educacionais, culturais, esportivas e de formação cidadã.
            A organização oferece uma série de programas e projetos, incluindo reforço escolar, cursos profissionalizantes, atividades artísticas e culturais, além de suporte psicológico e assistencial. O objetivo é contribuir para o desenvolvimento pessoal e social desses jovens, capacitando-os para enfrentar os desafios da vida e construir um futuro melhor."
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['6_querubins1.png']}
            altSet={['projeto Querubins']}
            title="Querubins"
            paragraph="A Associação Querubins é uma entidade sem fins lucrativos que se dedica ao desenvolvimento de crianças e jovens por meio da arte, da cultura e do esporte, com foco no educando como protagonista"
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['8_Sociedade_Crescer1.png']}
            altSet={['projeto Sociedade Crescer']}
            title="Sociedade Crescer"
            paragraph="A Sociedade Crescer é um projeto que atende 160 crianças e adolescentes no contraturno escolar realizando atividades socioeducativas. Também fornece refeições para os alunos do projeto e ajuda sempre que possível as famílias dos atendidos."
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['10_Corujinha1.png']}
            altSet={['projeto Corujinha (Golfe)']}
            title="Corujinha (Golfe)"
            paragraph="O Projeto Corujinha é um programa social voltado para a educação e desenvolvimento de crianças em situação de vulnerabilidade social. Programa focado no golfe e que também oferece apoio educacional, cultural e social para crianças que vivem em comunidades carentes."
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['9_Arca_Do_Saber1.png']}
            altSet={['projeto Arca do Saber']}
            title="Arca do Saber"
            paragraph="A Arca do Saber derruba muros e constrói pontes há mais de 20 anos, acolhendo, educando, e protegendo crianças e adolescentes, bem como estendendo o cuidado para suas famílias. As atividades e oficinas oferecidas visam proporcionar experiências culturais, lúdicas e esportivas, promovendo expressão, aprendizado, interação, sociabilidade e proteção social. Paralelamente, há um trabalho de fortalecimento das famílias, visando melhorar sua qualidade de vida, prevenir a ruptura dos vínculos familiares e comunitários, e superar situações de fragilidade social."
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['3_Tapera_SUP1.png']}
            altSet={['projeto TAPERA Stand Up Padle']}
            title="TAPERA Stand Up Padle"
            paragraph="Tapera Stand Up Paddle é um projeto social esportivo que atende o Centro de Convivência e Fortalecimento de Vínculos de Crianças e Adolescentes da Tapera em Florianópolis"
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['4_Faz1.png']}
            altSet={['projeto FAZ']}
            title="FAZ"
            paragraph="
            A Associação FAZ Alessandro Zarzur é uma ONG sem fins lucrativos que acredita na educação integral. Atendemos crianças e adolescentes entre 8 e 18 anos de idade com o objetivo de formar cidadãos multiplicadores do bem, protagonistas das suas histórias, respeitadores das diferenças e conscientes dos seus direitos e deveres.
            
            A FAZ realiza um importante trabalho com dedicação e carinho junto aos moradores em situação de vulnerabilidade social das comunidades do Real Parque, Jardim Panorama e entorno. Consolidou seu estatuto em 2018, dando seu primeiro passo para a realização do sonho da família Zarzur: perpetuar a memória do jovem atleta Alessandro Zarzur."
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['14_Pestaloozi1.png']}
            altSet={['projeto Projeto Pestalozzi']}
            title="Projeto Pestalozzi"
            paragraph="O projeto foi criado para capacitar jovens e adultos com deficiência intelectual/múltipla e Síndromes para ingressarem no mercado de trabalho profissional."
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['projeto_boavista.png']}
            altSet={['projeto Projeto Boa Vista']}
            title="Projeto Boa Vista"
            paragraph="Projeto Social que atende crianças e adolescentes de 06 a 17 anos em situação de vulnerabilidade"
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['13_Inhayba1.png']}
            altSet={['projeto Inhahyba']}
            title="Inhahyba"
            paragraph="A ACI atua desde 2002 transformando a realidade de crianças e adolescentes em situação de vulnerabilidade social. Nossa atuação foca em um território predominantemente rural localizado na periferia de Sorocaba que é perpassado por inúmeras violações de direitos."
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['16_Primeiro_Saque1.png']}
            altSet={['projeto Primeiro Saque']}
            title="Primeiro Saque"
            paragraph="O Primeiro Saque oferece práticas esportivas, de autoconhecimento, culturais e de sustentabilidade para crianças e jovens em situação de vulnerabilidade social na cidade de Canoas/RS."
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['15_Projeto_Uirapuru1.png']}
            altSet={['projeto Uirapuru']}
            title="Uirapuru"
            paragraph="O Projeto Uirapuru é um Programa Social idealizado e apoiado pela Fundação Heydenreich em Taboão da Serra- SP, desde 2007.
            Com cursos aprovados pelo Ministério do Trabalho e Emprego, atende gratuitamente jovens de 16 a 19 anos em Programas de Preparação para o Mercado de Trabalho e Programa de Aprendizagem."
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['12_Inclusao_Radical1.png']}
            altSet={['projeto INCLUSÃO RADICAL']}
            title="INCLUSÃO RADICAL"
            paragraph="Somos uma organização da sociedade civil sem fins lucrativos, cuja missão é garantir às pessoas com deficiência (PcD) o acesso às atividades outdoor. Tendo como público todas as faixas etárias: desde crianças, adolescentes e até idosos, temos como destino parques, trilhas, picos e montanhas da região. Tudo isso acompanhado do fomento da educação ambiental, defendendo e atuando através do conceito de mínimo impacto na natureza."
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['18_associacao_sem_fronteiras1.png']}
            altSet={['projeto ASSOCIAÇÃO SURF SEM FRONTEIRAS']}
            title="ASSOCIAÇÃO SURF SEM FRONTEIRAS"
            paragraph="Fundada em 2016, a Associação Surf Sem Fronteiras – ASSF é uma associação sem fins econômicos que tem por objetivo tornar o surf um esporte acessível para todas as pessoas. Queremos que todos tenham a oportunidade de exercer seu direito cidadão aos espaços de praia, para isso buscamos engajamento social de pessoas dispostas a contribuírem e participarem ativamente dessa transformação social!"
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['17_Em_arte_Cena_cidadania1.png']}
            altSet={['projeto EM CENA ARTE E CIDADANIA']}
            title="EM CENA ARTE E CIDADANIA"
            paragraph="A EM CENA ARTE E CIDADANIA é uma Associação da Sociedade Civil sem Fins Lucrativos, que atua na cidade do Recife, com a missão de promover educação artística, cultural e cidadã de crianças e adolescentes. Desenvolve, desde 1998, atividades de ensino, pesquisa e produção na área de artes cênicas, compreendendo dança, teatro e de música."
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['20_Instituto_Paulo_Freire1.png']}
            altSet={['projeto PAULO FREIRE']}
            title="PAULO FREIRE"
            paragraph="O Instituto Paulo Freire de Ação Social é uma organização da sociedade civil, sem fins lucrativos, que trabalha desde 2008 com crianças e adolescentes com idade de 06 a 14 anos e 11 meses em diversas oficinas, mantendo-os ocupados e afastados das ruas"
            classe="h-7"
          />
        </SwiperSlide>

        <SwiperSlide className="infoCard">
          <FoundationCard
            imgSet={['19_Brasilerinhos1.png']}
            altSet={['projeto BRASILEIRINHOS']}
            title="BRASILEIRINHOS"
            paragraph="Transformando vidas por meio da ginástica artística! 
            O projeto Brasileirinhos, criado pela inspiradora ginasta Daiane dos Santos, tem o objetivo de proporcionar o acesso à iniciação esportiva a crianças carentes. 
            Diante das dificuldades de encontrar esporte gratuito em comunidades humildes, esta iniciativa se dedica a levar a magia da ginástica artística para aqueles que mais necessitam."
            classe="h-7"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default FoundationTopCarousel
